<!--
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2022-01-07 10:37:31
 * @LastEditors: 番茄
 * @LastEditTime: 2022-01-07 16:43:28
-->
<template>
  <div class="recommend-container">
    <div class="header">
      <p>纵有千古 横有八荒</p>
      <p>前途似海 来日方长</p>
    </div>
    <nut-tabs v-model="tab11value"
              title-scroll
              direction="vertical"
              @click="checkBookClass">
      <nut-tabpane v-for="(item, index) in classData"
                   :key="index"
                   :title="item.title">
        <div class="title">
          <div class="fixed">
            <span>{{item.headDesc}}</span>
            <span>{{time}}更新</span>
          </div>
        </div>
        <div class="content">
          <div v-for="(item2,index2) in item.bookData"
               @click="toChapter(item2)"
               :key="index2">
            <img :src="item2.bookImg"
                 alt="">
            <div class="book-box">
              <p>{{item2.bookName}}</p>
              <p>{{item2.bookAuthor}}</p>
            </div>
          </div>

        </div>
      </nut-tabpane>
    </nut-tabs>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { getBookClass } from '@/api/search'
import dayjs from 'dayjs' // ES 2015

export default {
  setup() {
    const tab11value = ref('0')
    const classData = ref([
      {
        title: '玄幻',
        url: 'https://www.bige7.com/wuxia/',
        headDesc: '基于近7天搜索玄幻小说次数计算',
      },
      {
        title: '武侠',
        url: 'https://www.bige7.com/xuanhuan/',
        headDesc: '基于近7天搜索武侠小说次数计算',
      },
      {
        title: '科幻',
        url: 'https://www.bige7.com/kehuan/',
        headDesc: '基于近7天搜索科幻小说次数计算',
      },
      {
        title: '言情',
        url: 'https://www.bige7.com/mm/',
        headDesc: '基于近7天搜索言情小说次数计算',
      },
      {
        title: '完结',
        url: 'https://www.bige7.com/finish/',
        headDesc: '基于昨天最热完本小说计算',
      },
    ])

    const time = ref(null)
    onMounted(() => {
      time.value = dayjs().format('MM月DD日')
      checkBookClass({ ...classData.value[0], paneKey: 0 })
    })

    const checkBookClass = async (item) => {
      const { url } = classData.value.find((v) => v.title === item.title)
      try {
        let res = await getBookClass({ className: url })
        classData.value[item.paneKey].bookData = [...res.data]
      } catch (error) {
        console.log(error)
      }
    }

    const router = useRouter()
    const bookList = ref([])
    const toChapter = (item) => {
      // 点击书籍
      bookList.value = JSON.parse(localStorage.getItem('bookList')) || []
      if (bookList.value.length) {
        // 如果看过书
        let flag = bookList.value.find((v) => v.bookPath === item.bookPath)
        if (flag) {
          // 看过书且有记录
          router.push({
            name: 'chapter',
            params: { bookItem: JSON.stringify(flag), isFromHome: true },
          })
          return true
        }
      }
      router.push({
        name: 'chapter',
        params: { bookItem: JSON.stringify(item) },
      })
    }

    return {
      tab11value,
      classData,
      time,
      checkBookClass,
      toChapter,
    }
  },
}
</script>

<style lang="scss" scoped>
.recommend-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    padding: 7.5px 0;
    background: rgb(255, 218, 49);
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 700;
    :first-child {
      margin-bottom: 5px;
    }
  }

  ::v-deep .nut-tabs {
    // flex: 1;
    // min-height: 151vw;
    // min-height: 100%;
    flex: 1;
    .nut-tabpane {
      padding: 0 0px 0 15px;
      display: flex;
      flex-direction: column;
    }
  }

  ::v-deep .nut-tabs.vertical .nut-tabs__titles {
    width: 22.7vw;
  }

  ::v-deep
    .nut-tabs.vertical
    .nut-tabs__titles-item.active
    .nut-tabs__titles-item__line {
    background: rgb(248, 204, 0);
  }

  .title {
    position: relative;
    background: #fff;
    height: 32px;
    display: flex;
    align-items: center;
    z-index: 99;
    .fixed {
      width: 90%;
      position: fixed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #ccc;
    }
  }

  .content {
    flex: 1;
    overflow: auto;
    > div {
      display: flex;
      margin-bottom: 10px;
    }
    img {
      width: 50px;
    }

    .book-box {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      :first-child {
        font-weight: 700;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
        padding-top: 3px;
      }
      :last-child {
        font-size: 12px;
        color: #ccc;
        margin-top: 10px;
      }
    }
  }
}
</style>